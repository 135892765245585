import { GoogleMap, InfoWindow, InfoWindowF, Marker, MarkerF, useJsApiLoader } from '@react-google-maps/api';
import { useCallback, useState, memo, useRef } from 'react';
import LoadingContainer from '../../../components/LoadingContent';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { useEffect } from 'react';
import { Link, Stack } from '@mui/material';

const MarkerAndInfoWindow = ({ item, map }) => {
    const checkin = item;

    const markerRef = useRef();
    const infoRef = useRef();

    const onload = marker => {
        markerRef.current = marker;
    };

    const onInfoLoad = info => {
        infoRef.current = info;
    };
    return (
        <>
            <MarkerF
                visible={true}
                onLoad={onload}
                onClick={ ()=> infoRef.current.open({map, marker: markerRef.current}) }
                position={{ lat: Number(checkin.split(',')[0]), lng: Number(checkin.split(',')[1]) }}
            />
            {/* <InfoWindowF onLoad={onInfoLoad} anchor={markerRef.current} position={{ lat: Number(checkin.split(',')[0]), lng: Number(checkin.split(',')[1]) }}>
                <Stack direction="column">
                    <Typography variant="body">
                        <strong>Prestador:</strong>
                        {Order.Professional?.User.name}
                    </Typography>
                    <Typography variant="body">
                        <strong>Cliente:</strong> {Order.Customer?.customer_name}
                    </Typography>
                    <Typography variant="body">
                        <strong>Atividade:</strong> {Order.Activity?.name}
                    </Typography>
                    <Button sx={{mt:2}} size='small' href='/orders/1' variant='contained' > Detalhes da ordem </Button>
                </Stack>
            </InfoWindowF> */}
        </>
    );
};

export default ({ points }) => {
    const { isLoaded, loadError } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: process.env.REACT_APP_GOOGLEKEY,
    });

    const [isMounted, setIsMounted] = useState(false);
    useEffect(() => setIsMounted(true), []);

    const [map, setMap] = useState(null);

    const [center, setCenter] = useState({
        lat: -16.6956554,
        lng: -49.2913654,
    });

    const containerStyle = {
        minWidth: '100%',
        minHeight: '500px',
    };

    useEffect(() => {
        if (Boolean(points.length) && Boolean(map)) {
            const bounds = new window.google.maps.LatLngBounds();
            points.forEach((checkin) => bounds.extend({ lat: Number(checkin.split(',')[0]), lng: Number(checkin.split(',')[1]) }));
            map.fitBounds(bounds);
            setMap(map);
        }
    }, [points, map]);

    const onLoad = useCallback(map => {
        const bounds = new window.google.maps.LatLngBounds(center);
        map.fitBounds(bounds);
        setMap(map);
    }, []);

    const onUnmount = useCallback(map => {
        setMap(null);
    }, []);

    return isLoaded ? (
        <GoogleMap mapContainerStyle={containerStyle} center={center} zoom={15} onLoad={onLoad} onUnmount={onUnmount}>
            {points?.map(item => {
                return <MarkerAndInfoWindow key={item.id} item={item} map={map} />;
            })}
        </GoogleMap>
    ) : (
        <LoadingContainer label="Carregando mapa" />
    );
};

 
