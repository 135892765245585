import React, { useState } from 'react';
import { TextField, Button, Grid, Typography } from '@mui/material';
import Paper from '@mui/material/Paper';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import useHandleErrors from '../../../hooks/useHandleErrors';
import api from '../../../services/api';
import { Stack } from '@mui/system';
import GlobalPaper from '../../../components/GlobalPaper';
import { LoadingButton } from '@mui/lab';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';

export default function NewCustomer() {
    const [error, setError] = useState();
    useHandleErrors(error);

    const { enqueueSnackbar } = useSnackbar();
    const navigation = useNavigate();

    const costumerFormScheema = Yup.object().shape({
        name: Yup.string().min(3, 'Nome do Responsável Incompleto').required('Campo obrigatório'),
        email: Yup.string().email('E-mail inválido.'),//.required('Campo obrigatório'),
        customer_name: Yup.string().min(5, 'Nome da empresa').required('Campo obrigatório'),
        document: Yup.string().min(10, 'Documento deve ser maior ou igual a 10').max(14, 'Documento Incompleto'),//.required('Campo obrigatório'),
        cellphone: Yup.string().min(10, 'Número deve ser maior ou igual a 10').max(11, 'Número excedido'),//.required('Campo obrigatório'),
        zipcode: Yup.string().length(8, 'CEP deve conter 8 caracteres').required('Campo obrigatório'),
        address: Yup.string().required('Campo obrigatório'),
        complement: Yup.string().min(6, 'Complemento Incompleto'),//.required('Campo obrigatório'),
        city: Yup.string().min(4, 'Cidade Incorreta').required('Campo obrigatório'),
        state: Yup.string().length(2, 'Use duas letras. Ex: RJ').required('Campo obrigatório'),
        details: Yup.string().min(6, 'Número minímo de caracteres: 6').nullable(),
    });

    const { handleChange, handleBlur, handleReset, handleSubmit, values, errors, isSubmitting, setFieldValue } = useFormik({
        initialValues: {
            name: '',
            email: '',
            customer_name: '',
            document: '',
            cellphone: '',
            zipcode: '',
            address: '',
            complement: '',
            city: '',
            state: '',
            details: '',
        },
        validationSchema: costumerFormScheema,
        onSubmit: async (values, { setSubmitting }) => {
            try {
                setSubmitting(true);
                const { data } = await api.post('/customer', values);
                setSubmitting(false);
                enqueueSnackbar('Cliente adicionado', { variant: 'success' });
                navigation(`/customers/${data.data.id}`);
            } catch (error) {
                setSubmitting(false);
                setError(error);
            }
        },
    });

    return (
        <>
            <GlobalPaper>
                <Typography variant="h3" sx={{ mb: 3 }}>
                    Novo cliente
                </Typography>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={4}>
                        <TextField
                            fullWidth
                            label="Responsável"
                            variant="outlined"
                            sx={{ mb: '1rem' }}
                            error={errors.name}
                            helperText={errors.name}
                            name="name"
                            onChange={handleChange}
                            value={values.name}
                        />
                    </Grid>

                    <Grid item xs={12} md={4}>
                        <TextField
                            fullWidth
                            label="E-mail"
                            variant="outlined"
                            sx={{ mb: '1rem' }}
                            error={errors.email}
                            helperText={errors.email}
                            name="email"
                            onChange={handleChange}
                            value={values.email}
                        />
                    </Grid>

                    <Grid item xs={12} md={4}>
                        <TextField
                            fullWidth
                            type="text"
                            label="Nome da empresa"
                            variant="outlined"
                            sx={{ mb: '1rem' }}
                            error={errors.customer_name}
                            helperText={errors.customer_name}
                            name="customer_name"
                            onChange={handleChange}
                            value={values.customer_name}
                        />
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <TextField
                            fullWidth
                            type="text"
                            label="Documento"
                            variant="outlined"
                            sx={{ mb: '1rem' }}
                            error={errors.document}
                            helperText={errors.document}
                            name="document"
                            onChange={handleChange}
                            value={values.document}
                            maxLength={14}
                        />
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <TextField
                            fullWidth
                            type="text"
                            label="Telefone"
                            variant="outlined"
                            sx={{ mb: '1rem' }}
                            error={errors.cellphone}
                            helperText={errors.cellphone}
                            name="cellphone"
                            onChange={handleChange}
                            value={values.cellphone}
                            maxLength={11}
                        />
                    </Grid>

                    <Grid item xs={12} md={4}>
                        <TextField
                            fullWidth
                            label="CEP"
                            variant="outlined"
                            sx={{ mb: '1rem' }}
                            error={errors.zipcode}
                            helperText={errors.zipcode}
                            name="zipcode"
                            onChange={handleChange}
                            value={values.zipcode}
                            maxLength={8}
                        />
                    </Grid>

                    <Grid item xs={12} md={4}>
                        <TextField
                            fullWidth
                            label="Endereço"
                            variant="outlined"
                            sx={{ mb: '1rem' }}
                            error={errors.address}
                            helperText={errors.address}
                            name="address"
                            onChange={handleChange}
                            value={values.address}
                        />
                    </Grid>

                    <Grid item xs={12} md={4}>
                        <TextField
                            fullWidth
                            label="Complemento"
                            variant="outlined"
                            sx={{ mb: '1rem' }}
                            error={errors.complement}
                            helperText={errors.complement}
                            name="complement"
                            onChange={handleChange}
                            value={values.complement}
                        />
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <TextField
                            fullWidth
                            label="Cidade"
                            variant="outlined"
                            sx={{ mb: '1rem' }}
                            error={errors.city}
                            helperText={errors.city}
                            name="city"
                            onChange={handleChange}
                            value={values.city}
                        />
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <TextField
                            fullWidth
                            label="Estado"
                            variant="outlined"
                            sx={{ mb: '1rem' }}
                            error={errors.state}
                            helperText={errors.state}
                            name="state"
                            onChange={handleChange}
                            value={values.state}
                            maxLength={2}
                        />
                    </Grid>

                    <Grid item xs={12} md={12}>
                        <TextField
                            fullWidth
                            label="Detalhes"
                            variant="outlined"
                            sx={{ mb: '3rem' }}
                            error={errors.details}
                            helperText={errors.details}
                            name="details"
                            onChange={handleChange}
                            value={values.details}
                        />
                    </Grid>
                </Grid>

                <Stack direction="row" justifyContent="flex-end">
                    <LoadingButton loading={isSubmitting} type="submit" size="large" variant="contained" onClick={handleSubmit}>
                        Salvar
                    </LoadingButton>
                </Stack>
            </GlobalPaper>
        </>
    );
}
