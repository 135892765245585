import { Outlet } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import ContainerGlobal from './ContainerGlobal';
import { ProtectedLogin } from './ProtectedLogin';

export const ProtectedContainer = () => {
    const { logged, user} = useAuth();
    if (!logged && !Boolean(Object.keys(user).length)) return <ProtectedLogin />;
     
    return (
        <ContainerGlobal>
            <Outlet />
        </ContainerGlobal>
    );
};
