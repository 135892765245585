import React, { useEffect, useState } from 'react';
import {
    Stack,
    Grid,
    FormHelperText,
    TextField,
    Paper,
    Button,
    Chip,
    Typography,
    IconButton,
    CircularProgress,
    Rating,
    ButtonGroup,
} from '@mui/material';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import useHandleErrors from '../../../hooks/useHandleErrors';
import DatePicker from 'react-multi-date-picker';
import CalendarIcon from '@mui/icons-material/CalendarToday';
import gregorian_pt from '../../../global/gregorian_pt';
import { SelectCustomers } from './SelectCustomers';
import { SelectActivitiesOrders } from './SelectActivitiesOrders';
import { SelectProfessional } from './SelectProfessional';
import api from '../../../services/api';
import { LoadingButton } from '@mui/lab';
import { ListServices } from './ServicesList';
import { useNavigate } from 'react-router-dom';
import { Save } from '@mui/icons-material';
import { useSnackbar } from 'notistack';
import GlobalPaper from '../../../components/GlobalPaper';
import ShareIcon from '@mui/icons-material/Share';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Grow from '@mui/material/Grow';
import Popper from '@mui/material/Popper';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import ClickAwayListener from '@mui/material/ClickAwayListener';

const status = {
    IN_PROGRESS: 'Em andamento',
    CONCLUDED: 'Concluído',
};
export default function OrderDetails({ data, mutate }) {
    const { enqueueSnackbar } = useSnackbar();

    // const tomorrow = new Date();
    // tomorrow.setDate(tomorrow.getDate() + 1);

    const [open, setOpen] = React.useState(false);
    const anchorRef = React.useRef(null);
    const [concludedServices, setConcludedServices] = useState([]);

    const ContainerOrdersScheema = Yup.object().shape({
        activity_id: Yup.number().required('Campo obrigatório'),
        professional_id: Yup.string().required('Campo obrigatório'),
        customer_id: Yup.string().required('Campo obrigatório'),
        details: Yup.string().min(6, 'O minimo de caracteres são 6.').required('Campo obrigatório'),
        scheduled: Yup.array().required('Campo obrigatório'),
    });

    const [error, setError] = useState();

    const navigation = useNavigate();

    useHandleErrors(error);

    const { handleChange, handleSubmit, handleReset, values, errors, isSubmitting, setFieldValue, setValues } = useFormik({
        initialValues: {
            activity_id: '',
            scheduled: [],
            professional_id: '',
            customer_id: '',
            details: '',
        },
        validationSchema: ContainerOrdersScheema,
        onSubmit: async (values, { setSubmitting }) => {
            try {
                setSubmitting(true);
                if (values.id) {
                    await api.put(`/order/${values.id}`, values);
                    await mutate();
                    enqueueSnackbar('Dados alterados', { variant: 'success' });
                } else {
                    const response = await api.post('/order', values);
                    enqueueSnackbar('Ordem criada', { variant: 'success' });
                    handleReset();
                    navigation(`/orders/${response.data.data.id}`);
                }
                 
                setSubmitting(false);
            } catch (error) {
                setError(error);
            }
        },
    });

    useEffect(() => {
        if (data) {
            setValues({ ...data, scheduled: data?.Services.map(({ scheduled }) => scheduled) }, false);
            setConcludedServices(data.Services.map(({ status, scheduled }) => status && scheduled).filter(item => item));
        }
    }, [data]);

    const [isSharing, setIsSharing] = useState(false);
    const shareLink = async () => {
        try {
            setIsSharing(true);

            const response = await api.get(`order/${data.id}/share`);
            console.log(`${window.location.origin}/order/shared/${response.data.data.share_id}`);
            navigator.clipboard.writeText(`${window.location.origin}/order/shared/${response.data.data.share_id}`);
            setIsSharing(false);
            enqueueSnackbar('Link copiado', { variant: 'success' });
        } catch (error) {
            console.log(error);
        }
    };

    const CustomSelectDate = ({ openCalendar, value }) => {
        value = value.map(item => <Chip sx={{ fontWeight: 900, mr: 1, mb: 1 }} key={item} label={new Date(item).toLocaleDateString()} />);

        return (
            <Grid container direction="row" spacing={2}>
                <Grid item xs={12} md={3.5}>
                    <Button endIcon={<CalendarIcon />} variant="contained" onClick={openCalendar}>
                        Selecionar datas
                    </Button>
                </Grid>
                <Grid item xs>
                    <Stack direction="row" flexWrap="wrap">
                        {value}
                    </Stack>
                </Grid>
            </Grid>
        );
    };

    return (
        <>
            <GlobalPaper elevation={3}>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                        <Typography variant="h3" sx={{ mb: '2rem' }}>
                            {data ? `Editando Ordem de Serviços #${data.id}` : 'Nova Ordem de Serviços'}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Stack direction="row" spacing={2} justifyContent="flex-end">
                            {data && (
                                <>
                                    {Boolean(data.Rating) ? (
                                        <Rating value={data.Rating.rating_value} />
                                    ) : (
                                        <IconButton sx={{ backgroundColor: 'primary.main' }} onClick={e => shareLink()}>
                                            {isSharing ? <CircularProgress size={30} color="inherit" /> : <ShareIcon color="inherit" />}
                                        </IconButton>
                                    )}
                                </>
                            )}
                        </Stack>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <SelectActivitiesOrders
                            name="activity_id"
                            value={values.activity_id}
                            onChange={setFieldValue}
                            error={errors.activity_id}
                            helperText={errors.activity_id}
                        />
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <SelectProfessional
                            name="professional_id"
                            value={values.professional_id}
                            activity_id={values.activity_id}
                            onChange={setFieldValue}
                            error={errors.professional_id}
                            helperText={errors.professional_id}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <SelectCustomers
                            value={values.customer_id}
                            name="customer_id"
                            onChange={setFieldValue}
                            error={errors.customer_id}
                            helperText={errors.customer_id}
                        />
                    </Grid>

                    <Grid item xs={12} md={12}>
                        <Stack direction="column" sx={{ border: '1px solid rgba(0, 0, 0, 0.2)', borderRadius: 1.2, padding: 2 }}>
                            <DatePicker
                                onChange={e => setFieldValue('scheduled', e)}
                                multiple
                                name="scheduled"
                                numberOfMonths={2}
                                locale={gregorian_pt}
                                value={values.scheduled}
                                required
                                render={<CustomSelectDate />}
                                mapDays={({ date }) => {
                                    if (concludedServices.includes(new Date(`${date.month}/${date.day}/${date.year} 21:00`).toISOString())) {
                                        return {
                                            disabled: true,
                                            style: { background: 'green', color: '#fff' },
                                            onClick: () => alert('Dia concluído. Não pode ser removido.'),
                                        };
                                    }
                                }}
                            />
                            <FormHelperText error={Boolean(errors.scheduled)}>{errors.scheduled}</FormHelperText>
                        </Stack>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            multiline
                            label="Coloque os detalhes aqui"
                            variant="outlined"
                            error={Boolean(errors.details)}
                            helperText={errors.details}
                            name="details"
                            onChange={handleChange}
                            value={values.details}
                        />
                    </Grid>

                    <Grid item container direction="row" sx={{ justifyContent: 'flex-end' }}>
                        <ButtonGroup variant="contained" ref={anchorRef}>
                            <LoadingButton
                                // endIcon={<Save />}
                                onClick={() => handleSubmit()}
                                disabled={isSubmitting}
                                loading={isSubmitting}
                                variant="contained">
                                Salvar Dados
                            </LoadingButton>
                            {data && (
                                <Button
                                    size="small"
                                    aria-controls={open ? 'split-button-menu' : undefined}
                                    aria-expanded={open ? 'true' : undefined}
                                    aria-label="select merge strategy"
                                    aria-haspopup="menu"
                                    onClick={() => setOpen(true)}>
                                    <ArrowDropDownIcon />
                                </Button>
                            )}
                        </ButtonGroup>
                        <Popper
                            sx={{
                                zIndex: 1,
                            }}
                            open={open}
                            anchorEl={anchorRef.current}
                            role={undefined}
                            placement="bottom-end"
                            transition
                            disablePortal>
                            {({ TransitionProps, placement }) => (
                                <Grow
                                    {...TransitionProps}
                                    style={{
                                        transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
                                    }}>
                                    <Paper>
                                        <ClickAwayListener onClickAway={() => setOpen(false)}>
                                            <MenuList id="split-button-menu" autoFocusItem>
                                                {['IN_PROGRESS', 'CONCLUDED'].map((option, index) => (
                                                    <MenuItem
                                                        key={option}
                                                        //disabled={index === 2}
                                                        selected={values.status === option}
                                                        onClick={event => {
                                                            setFieldValue('status', option);
                                                            setOpen(false);
                                                        }}>
                                                        {status[option]}
                                                    </MenuItem>
                                                ))}
                                            </MenuList>
                                        </ClickAwayListener>
                                    </Paper>
                                </Grow>
                            )}
                        </Popper>
                    </Grid>
                </Grid>
            </GlobalPaper>
            {data && <ListServices data={data} />}
        </>
    );
}
