import { Routes, Route } from 'react-router-dom';
import { Login } from '../modules/Auth/screens/Login';
import { ProtectedContainer } from '../components/ProtectedContainer';
import Dashboard from '../modules/Dashboard';
import Settings from '../modules/Settings';
import Team from '../modules/Team/screens';
import { EditUsers } from '../modules/Team/screens/EditUsers';
import ListUsers from '../modules/Team/screens/ListUsers';
import { NewUser } from '../modules/Team/screens/NewUser';
import Customers from '../modules/Customers/screens';
import CustomersList from '../modules/Customers/screens/CustomersList';
import EditCustomers from '../modules/Customers/screens/EditCustomers';
import NewCustomer from '../modules/Customers/screens/NewCustomer';
import Orders from '../modules/Orders/screens';
import OrdersList from '../modules/Orders/screens/OrdersList';
import OrdersNew from '../modules/Orders/screens/OrdersNew';
import OrdersEdit from '../modules/Orders/screens/OrdersEdit';
import { Tracker } from '../modules/Tracker';
import Shared from '../modules/Shared';

export default () => {
    return (
        <Routes>
            <Route index path="/" element={<Login />} />
            <Route   path="/order/shared/:uuid" element={<Shared />} />

            <Route element={<ProtectedContainer />}>
                <Route path="dashboard" element={<Dashboard />} />
                <Route path="settings" element={<Settings />} />

                <Route path="team" element={<Team />}>
                    <Route index element={<ListUsers />} />
                    <Route path=":id" element={<EditUsers />} />
                    <Route path="new" element={<NewUser />} />
                </Route>

                <Route path="customers" element={<Customers />}>
                    <Route index element={<CustomersList />} />
                    <Route path=":id" element={<EditCustomers />} />
                    <Route path="new" element={<NewCustomer />} />
                </Route>
                
                <Route path="orders" element={<Orders />}>
                    <Route index element={<OrdersList />} />
                    <Route path="new" element={<OrdersNew />} />
                    <Route path=":id" element={<OrdersEdit />} />
                </Route>

                <Route path="tracker" element={<Tracker />} />
            </Route>
        </Routes>
    );
};
