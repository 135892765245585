import React, { useState } from 'react';
import { useFetch } from '../../../hooks/useFetch';
import LoadingContainer from '../../../components/LoadingContent';
import { Box, Button } from '@mui/material';
import DialogAddActivity from './DialogAddActivity';
import { DataGrid } from '@mui/x-data-grid';
import { useApp } from '../../../contexts/AppContext';

const ViewDetailsButton = ({ params, item }) => {
    const { setEditAct, setOpenAct } = useApp();
    return (
        <Button
            variant="outlined"
            onClick={() => {
                setEditAct(item);
                setOpenAct(true);
            }}
            size="small"
            style={{ marginLeft: 16, fontSize: 10 }}
            tabIndex={params.hasFocus ? 0 : -1}>
            Editar
        </Button>
    );
};

const columns = [
    {
        field: 'id',
        headerName: 'ID',
        width: 60,
    },
    {
        field: 'name',
        headerName: 'Atividade',
        minWidth: 250,
        flex: 1,
    },
    {
        field: 'actions',
        type: 'actions',
        width: 120,
        renderCell: params => <ViewDetailsButton params={params} item={params.row} />,
    },
];

export default function ListActivities({ handleClickOpen, handleClose, open }) {
    const [pageSize, setPageSize] = useState(10);
    const [page, setPage] = useState(1);
    const { openAct, setOpenAct, editAct, setEditAct } = useApp();

    const { data, error, mutate, isFetching } = useFetch('/activity', {
        params: {
            limit: pageSize,
            page: page,
            paged: true,
        },
    });

    if (isFetching) return <LoadingContainer />;

    const { rows, pagination } = data;

    return (
        <>
            <Box sx={{ height: 400, width: '100%' }}>
                <DataGrid
                    columns={columns}
                    loading={isFetching}
                    pageSize={pageSize}
                    onPageChange={newPage => setPage(newPage)}
                    onPageSizeChange={newPageSize => setPageSize(newPageSize)}
                    rows={rows}
                    rowsPerPageOptions={[10, 25, 100]}
                    disableSelectionOnClick
                />
            </Box>
            <DialogAddActivity handleClose={() => {setOpenAct(false);setEditAct() }} open={openAct} mutate={mutate} data={editAct}></DialogAddActivity>
        </>
    );
}
