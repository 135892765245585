import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import { useFetch } from '../../../hooks/useFetch';
import LoadingContainer from '../../../components/LoadingContent';
import { useNavigate } from 'react-router-dom';
import { DataGrid } from '@mui/x-data-grid';
import { Grid, Typography } from '@mui/material';
import GlobalPaper from '../../../components/GlobalPaper';

const ViewDetailsButton = ({ params, id }) => {
    const navigation = useNavigate();

    return (
        <Button
            variant="outlined"
            onClick={() => navigation(`/customers/${id}`)}
            size="small"
            style={{ marginLeft: 16, fontSize: 12 }}
            tabIndex={params.hasFocus ? 0 : -1}>
            Detalhes
        </Button>
    );
};

const columns = [
    {
        field: 'id',
        headerName: 'ID',
        width: 60,
    },
    {
        field: 'customer_name',
        headerName: 'Nome',
        minWidth: 250,
        flex: 1,
    },
    {
        field: 'document',
        headerName: 'CNPJ/CPF',
        minWidth: 250,
        flex: 1,
    },
    {
        field: 'actions',
        type: 'actions',
        width: 120,
        renderCell: params => <ViewDetailsButton params={params} id={params.row.id} />,
    },
];

export default function CustomersList() {
    const [pageSize, setPageSize] = useState(10);
    const [page, setPage] = useState(0);

    const { data, error, mutate, isFetching } = useFetch('/customer', {
        params: {
            limit: pageSize,
            page: page,
        },
    });

    if (isFetching) return <LoadingContainer />;

    const { rows } = data;

    return (
        <>
            <GlobalPaper elevation={3}>
                <Typography variant="h3" sx={{ mb: 3 }}>
                    Lista de Clientes
                </Typography>
                <Box sx={{ height: 400, width: '100%' }}>
                    <DataGrid
                        columns={columns}
                        style={{ border: 'none' }}
                        loading={isFetching}
                        // pageSize={pageSize}
                        // onPageChange={newPage => setPage(newPage)}
                        // onPageSizeChange={newPageSize => setPageSize(newPageSize)}
                        rows={rows}
                        rowsPerPageOptions={[10, 25, 100]}
                        disableSelectionOnClick
                    />
                </Box>
            </GlobalPaper>
        </>
    );
}
