import React, { useState } from 'react';
import { Box, Button, Stack } from '@mui/material';
import ListActivities from './ListActivities';
import { useApp } from '../../../contexts/AppContext';

export default function TabActivities() {
   const {setOpenAct} = useApp()

    return (
        <Box>
            <Stack sx={{ alignItems: 'flex-end', pt: 2, pb: 2 }}>
                <Button onClick={()=>setOpenAct(true)} sx={{color: '#FAFAFA'}} variant="contained">
                    Adicionar atividade
                </Button>
            </Stack>
            <ListActivities />
        </Box>
    );
}
