import React, { useState } from 'react';
import { Button, Stack } from '@mui/material';
import ListAsks from './ListAsks';
import { useApp } from '../../../contexts/AppContext';

export default function TabAsks() {
     
    const {setOpenAsk} = useApp()
    
    return (
        <>
            <Stack sx={{ alignItems: 'flex-end', pt: 2, pb: 2 }}>
                <Button onClick={()=>setOpenAsk(true)} sx={{color: '#FAFAFA'}} variant="contained">
                    Adicionar checklist
                </Button>
            </Stack>
            <ListAsks />
        </>
    );
}
