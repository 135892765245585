import React, { createContext, useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useAuth } from './AuthContext';
import { useSnackbar } from 'notistack';
import useLocalStorage from '../hooks/useLocalStorage';
import useSound from 'use-sound';
import noty from '../assets/noty.wav';
import notynew from '../assets/notynew.mp3';

export const AppContext = createContext({});

export const AppProvider = ({ children }) => {
    const [play] = useSound(noty);
    const [newMsgSound] = useSound(notynew);
    const { socket, logged } = useAuth();
    const { enqueueSnackbar } = useSnackbar();
    const [loading, setLoading] = useState(false);
    const [newChats, setNewChats] = useLocalStorage('newChats', []);
    const [openedChatService, setOpenedChatService] = useState();

    const verifyChatsWaiting = response => {
        enqueueSnackbar('Nova mensagem', { variant: 'info', preventDuplicate: true });
        newChats.push(response.service_id);
        setNewChats([...new Set(newChats)]);
        play();
    };
    const ifOpened = (response, opened) => {
        if (!Boolean(opened)) {
            console.log('Chat fechado');
            verifyChatsWaiting(response);
        }

        if (Boolean(opened)) {
            console.log(opened?.id !== response.service_id);
            if (opened?.id !== response.service_id) {
                console.log('Aberto mas diferente');
                verifyChatsWaiting(response);
            }
        }
    };
    useEffect(() => {
        if (logged) {
            socket?.on('admin', async response => {
                ifOpened(response, openedChatService);
            });
        }
    }, [openedChatService, socket, logged]);

    const [openAct, setOpenAct] = useState(false);
    const [editAct, setEditAct] = useState();

    const [openAsk, setOpenAsk] = useState(false);
    const [editAsk, setEditAsk] = useState();

    return (
        <AppContext.Provider
            value={{
                loading,
                setLoading,
                newChats,
                setNewChats,
                openedChatService,
                setOpenedChatService,
                newMsgSound,
                openAct,
                setOpenAct,
                editAct,
                setEditAct,
                openAsk,
                setOpenAsk,
                editAsk,
                setEditAsk,
            }}>
            {children}
        </AppContext.Provider>
    );
};

export const useApp = () => {
    const context = useContext(AppContext);
    return context;
};
