import React, { useState } from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { Box, Button, DialogTitle, Divider, Grid, Stack, Table, TableCell, TableRow } from '@mui/material';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import { colors } from '../../../global/helpers';
ChartJS.register(ArcElement, Tooltip, Legend);

export function Graph({ data }) {
    const graph = {
        labels: data?.slice(0, 6).map(i => i['Activity.name']),
        datasets: [
            {
                label: 'Atividade mais executadas',
                data: data?.slice(0, 6).map(i => Number(i.total_activity)),
                backgroundColor: data?.slice(0, 6).map((i, k) => colors[k]),
            },
        ],
    };

    const [dialog, setDialog] = useState(false);

    return (
        <Card elevation={3} sx={{ borderRadius: '8px', flexGrow: 2, py: 5, alignItems: 'center' }}>
            <CardContent>
                <Grid direction="row" container px={4}>
                    <Grid item xs={6}>
                        <Typography variant="h3" sx={{ mb: 3 }}>
                            Atividades por execução
                        </Typography>
                        {data?.slice(0, 6).map((i, k) => (
                            <Stack key={k} direction="row" spacing={2} sx={{ mb: 1 }}>
                                <Box sx={{ width: 25, height: 22, backgroundColor: colors[k] }} />
                                <Typography>{`${i['Activity.name']} - ${i.total_activity}`}</Typography>
                            </Stack>
                        ))}

                        <Button onClick={() => setDialog(true)} variant="contained" sx={{ mt: 3 }}>
                            Ver todos
                        </Button>
                    </Grid>

                    <Grid item xs={6}>
                        <Doughnut width={250} height={250} options={{ responsive: true, plugins: { legend: { display: false } } }} data={graph} />
                    </Grid>
                </Grid>
            </CardContent>
            <Dialog open={dialog} keepMounted maxWidth="md" fullWidth>
                <Stack direction="row" justifyContent="space-between" display="flex" alignItems="center" sx={{ padding: '1rem' }}>
                    <DialogTitle>Atividades por execução</DialogTitle>
                    <Button onClick={() => setDialog(false)}>
                        <CloseOutlinedIcon />
                    </Button>
                </Stack>

                <DialogContent>
                    <Table>
                        {data.map(item => (
                            <TableRow key={item['Activity.name']}>
                                <TableCell>{item['Activity.name']}</TableCell>
                                <TableCell>{item.total_activity}</TableCell>
                            </TableRow>
                        ))}
                    </Table>
                </DialogContent>
            </Dialog>
        </Card>
    );
}
