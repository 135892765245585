import { Card, CardContent, Stack, TextField, Typography } from '@mui/material';
import lodash from 'lodash';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';

function CardAsk({ ask, answers }) {
    return (
        <Card variant="outlined" sx={{ width: '100%' }}>
            <CardContent>
                <Typography gutterBottom variant="h3">
                    {ask.Ask.question}
                </Typography>

                {ask.Ask.type === 'multiple' && (
                    <FormGroup>
                        {ask.Ask.AskOptions.map(item => (
                            <FormControlLabel
                                onClick={e => e.preventDefault()}
                                key={item.id}
                                control={<Checkbox checked={Boolean(answers) && answers.includes(item.option)} />}
                                label={item.option}
                            />
                        ))}
                    </FormGroup>
                )}

                {ask.Ask.type === 'single' && (
                    <RadioGroup value={answers}>
                        {ask.Ask.AskOptions.map(item => (
                            <FormControlLabel
                                onClick={e => e.preventDefault()}
                                key={item.id}
                                value={item.option}
                                control={<Radio />}
                                label={item.option}
                            />
                        ))}
                    </RadioGroup>
                )}

                {ask.Ask.type === 'text' && <TextField disabled fullWidth aria-readonly multiline minRows={2} value={answers}></TextField>}
            </CardContent>
        </Card>
    );
}
export default ({ asks, answers }) => {
    const asksIds = asks.map(item => item.Ask && item.Ask.id).filter(item => item);
    const sanityAnswers = lodash.groupBy(answers, 'ask_id');
    const q = lodash.groupBy(asks, 'Ask.id');

    Object.entries(sanityAnswers).forEach(item => {
        if (Boolean(q[item[0]])) {
            if (asksIds.includes(Number(item[0]))) {
                sanityAnswers[item[0]] =
                    q[item[0]][0].Ask.type === 'text'
                        ? sanityAnswers[item[0]].map(i => i.answer)[0]
                        : q[item[0]][0].Ask.type === 'single'
                        ? sanityAnswers[item[0]].map(i => i.answer)[0]
                        : sanityAnswers[item[0]].map(i => i.answer);
            }
        } else {
            delete sanityAnswers[item[0]];
        }
    });

    return (
        <Stack direction="column" spacing={3}>
            {asks.map(item => (
                <>{item.Ask && <CardAsk key={item.id} ask={item} answers={sanityAnswers[item.Ask.id]} />}</>
            ))}
        </Stack>
    );
};
