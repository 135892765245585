import React, { createContext, useContext, useEffect, useState } from 'react';
import api, { baseURL } from '../services/api';
import io from 'socket.io-client';
import { useRef } from 'react';

const AuthContext = createContext({});

export const AuthProvider = props => {
    const [token, setToken] = useState();
    const [user, setUser] = useState({});

    const [rooms, setRooms] = useState([]);

    const [serviceDetailDialog, setServiceDetailDialog] = useState();

    const socket = useRef();

    useEffect(() => {
        socket.current?.on('connect_error', e => console.log('Erro na conexão Socket'));
        socket.current?.on('disconnect', e => console.log('Socket desconectado'));
        return ()=> socket.current?.disconnect()
    }, []);

    const verifySession = async () => {
        const token = sessionStorage.getItem('token');

        if (token) {
            const sessionUser = sessionStorage.getItem('user');
            api.defaults.headers['Authorization'] = `Bearer ${token}`;
            setToken(token);

            setUser(JSON.parse(sessionUser));
            socket.current = io.connect(baseURL, {
                transports: ['websocket'],
            });
        } else {
            
            delete api.defaults.headers.Authorization;
            setToken();
            socket.current?.disconnect()
        }
    };

    useEffect(() => {
        verifySession();
    }, []);

    const LogOut = () => {
        sessionStorage.removeItem('token');
        sessionStorage.removeItem('user');
        verifySession();
    };

    return (
        <AuthContext.Provider
            value={{
                logged: Boolean(token),
                user,
                LogOut,
                verifySession,
                setUser,
                socket: socket.current,
                rooms,
                serviceDetailDialog,
                setServiceDetailDialog,
            }}>
            {props.children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => {
    const context = useContext(AuthContext);
    if (!context) throw 'Use Auth Context inside Auth Provider';

    return context;
};
