import React, { useRef } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import { useFetch } from '../hooks/useFetch';
import LoadingContainer from './LoadingContent';
import SupportChat from './SuportChat';
import CloseIcon from '@mui/icons-material/Close';
import { IconButton, Stack, Typography, useMediaQuery } from '@mui/material';

export default function SupportDialog({ handleClose, open, service }) {
    
    const bottomRef = useRef();
    const mobile = useMediaQuery(theme => theme.breakpoints.down('sm'));
    const { data, erros, mutate, isFetching } = useFetch(`/chat/${service.id}`, {
        params: {
            order: ['createdAt', 'ASC'],
        },
    });

    if (isFetching) return <LoadingContainer />;
    
    return (
        <>
            <Dialog ref={bottomRef} fullScreen={mobile} fullWidth maxWidth="sm" open={open} onClose={handleClose}>
                <DialogTitle component="div" onClose={handleClose}>
                    <Stack direction="row" alignItems="center" justifyContent="space-between" >
                        <div>
                            <Typography variant='body' >Orderm #{service.order_id} - {service.Order.Professional.User.name}</Typography>
                            <Typography variant='subtitle1' > {service.Order.Activity.name} - {service.Order.Customer.customer_name} </Typography>
                        </div>
                        <IconButton sx={{alignSelf: "flex-end"}} onClick={handleClose} ><CloseIcon /></IconButton>
                    </Stack>
                </DialogTitle>
                <SupportChat
                    service={service}
                    data={data}
                    order_id={service.order_id}
                    mutate={mutate}
                    isFetching={isFetching}
                    bottomRef={bottomRef}
                />
            </Dialog>
        </>
    );
}
