import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useState } from 'react';
import api from '../../../services/api';
import useHandleErrors from '../../../hooks/useHandleErrors';
import { useEffect } from 'react';
import { CircularProgress } from '@mui/material';
import { useSnackbar } from 'notistack';
import { SelectProfessional } from '../../../components/SelectProfessional';
import SelectMultipleProfessional from '../../../components/SelectMultipleProfessional';
import SelectMultipleAsks from '../../../components/SelectMultipleAsks';

export default function DialogAddActivity({ handleClose, open, mutate, data }) {
    const ValidateForm = Yup.object().shape({
        name: Yup.string().required('Campo obrigatório'),
    });

    const { enqueueSnackbar } = useSnackbar();

    const [errs, setErrs] = useState();
    useHandleErrors(errs);

    const { values, errors, touched, isSubmitting, handleChange, handleBlur, handleReset, handleSubmit, setValues, setFieldValue } = useFormik({
        initialValues: { id: '', name: '', ProfessionalActivities: [], ActivityAsks: [] },
        validationSchema: ValidateForm,
        onSubmit: async (values, { setSubmitting }) => {
            try {
                setSubmitting(true);
                if (Boolean(values.id)) {
                    await api.put(`/activity/${values.id}`, values);
                    enqueueSnackbar('Atividade alterada', { variant: 'success' });
                } else {
                    await api.post('/activity', values);
                    enqueueSnackbar('Atividade adicionada', { variant: 'success' });
                }
                mutate();
                setSubmitting(false);
                handleReset();
                handleClose();
            } catch (error) {
                setErrs(error);
            }
        },
    });

    useEffect(() => {
        if (!open) {
            handleReset();
        }
    }, [open]);

    useEffect(() => {
        if (data) {
            setValues(
                {
                    ...data,
                    ProfessionalActivities: data.ProfessionalActivities.map(item => item.Professional.id),
                    ActivityAsks: data.ActivityAsks.map(item => item.Ask.id),
                },
                false,
            );
        }
    }, [data]);
    return (
        <form>
            <Dialog open={open} onClose={handleClose} disabled={isSubmitting}>
                <DialogTitle>{data ? 'Editando atividade' : 'Adicionando atividades'}</DialogTitle>
                <DialogContent>
                    <DialogContentText>As atividades poderão ser designiadas aos profissionais e à ordem de serviço.</DialogContentText>
                    <TextField
                        autoFocus
                        required
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.name}
                        helperText={errors.name}
                        error={Boolean(errors.name) && touched.name}
                        margin="dense"
                        id="name"
                        label="Nome da atividade"
                        type="text"
                        name="name"
                        fullWidth
                        variant="outlined"
                        disabled={isSubmitting}
                    />
                    <SelectMultipleProfessional name="ProfessionalActivities" value={values.ProfessionalActivities} onChange={handleChange} />
                    <SelectMultipleAsks name="ActivityAsks" value={values.ActivityAsks} onChange={handleChange} />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} disabled={isSubmitting}>
                        Cancelar
                    </Button>
                    <Button
                        onClick={handleSubmit}
                        variant="contained"
                        disabled={isSubmitting}
                        startIcon={isSubmitting && <CircularProgress color="primary" size={20} sx={{ color: '#FAFAFA' }} />}>
                        Salvar
                    </Button>
                </DialogActions>
            </Dialog>
        </form>
    );
}
