export const colors = [
    'rgb(254,218,206)',
    'rgb(255,165,146)',
    'rgb(255,106,81)',
    'rgb(255,158,188)',
    'rgb(193,30,57)',
    'rgb(247,19,15)',
    'rgb(254,65,2)',
    'rgb(255,102,1)',
    'rgb(255,160,29)',
    'rgb(255,205,0)',
    'rgb(255,205,0)',
    'rgb(229,233,140)',
    'rgb(0,159,30)',
    'rgb(64,187,53)',
    'rgb(74,142,33)',
    'rgb(0,53,20)',
    'rgb(0,117,101)',
    'rgb(0,109,103)',
    'rgb(0,195,210)',
    'rgb(93,154,175)',
    'rgb(0,160,225)',
    'rgb(92,151,207)',
    'rgb(127,195,234)',
    'rgb(0,35,103)',
    'rgb(147,135,159)',
    'rgb(188,108,119)',
    'rgb(104,35,118)',
    'rgb(67,34,125)',
    'rgb(176,55,90)',
    'rgb(162,23,99)',
    'rgb(162,35,56)',
    'rgb(187,69,94)',
    'rgb(167,131,115)',
    'rgb(231,164,96)',
    'rgb(205,145,0)',
    'rgb(141,78,54)',
    'rgb(95,20,0)',
    'rgb(51,11,1)',
    'rgb(111,110,106)',
    'rgb(0,11,24)',
];