import React, { useState } from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { Box, Button, DialogTitle, Divider, Grid, Stack, Table, TableCell, TableRow } from '@mui/material';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import { colors } from '../../../global/helpers';
ChartJS.register(ArcElement, Tooltip, Legend);

export function RatingGraph({ rating_medium }) {
    const graph = {
        labels: ['Avaliação média', ''],
        datasets: [
            {
                label: 'Avaliação média',
                data: [Number(rating_medium[0].rating_medium).toFixed(2), Number(rating_medium[0].rating_medium).toFixed(2) - 5],
                backgroundColor: ['yellow', '#f6f6f6'],
            },
        ],
    };
    return (
        <Card elevation={3} sx={{ borderRadius: '8px', flexGrow: 2, py: 5, alignItems: 'center' }}>
            <CardContent>
                <Grid direction="row" container px={4} spacing={4}>
                    <Grid item xs={6}>
                        <Typography variant="h3" sx={{ mb: 3 }}>
                            Média de avaliação
                        </Typography>
                        <Typography variant="h3" sx={{ mb: 3, fontSize: 40 }}>
                            {Number(rating_medium[0].rating_medium).toFixed(2)}
                        </Typography>
                    </Grid>

                    <Grid item xs={6}>
                        <Doughnut width={250} height={250} options={{ responsive: true, plugins: { legend: { display: false } } }} data={graph} />
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
}
