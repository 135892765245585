import { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import LoadingContainer from './LoadingContent';
import { useAuth } from '../contexts/AuthContext';
import { CircularProgress, Dialog, Divider } from '@mui/material';
import SupportDialog from './SuportDialog';
import { useApp } from '../contexts/AppContext';

export default function SupportFeed({ data, mutate, isFetching }) {
    const { socket } = useAuth();
    const { newChats, setNewChats, openedChatService, setOpenedChatService } = useApp();

    if (isFetching) return <LoadingContainer />;

    return (
        <>
            {!Boolean(data.length) && (
                <Box
                    key="none_support"
                    sx={{
                        padding: '0.8rem',
                        height: '100%',
                        background: "#F8F9FA",
                        borderRadius: '8px',
                    }}>
                    <Typography sx={{ fontSize: '1.1rem', fontWeight: '700' }}>
                        Nenhum serviço em andamento
                    </Typography>
                </Box>
            )}
            {data?.map(item => (
                <Box
                    key={`feed_${item.id}`}
                    onClick={() => {
                        setOpenedChatService({...item});
                        newChats.splice(newChats.indexOf(item.service_id), 1);
                        setNewChats([...new Set(newChats)]);
                    }}
                    sx={{
                        padding: '0.8rem',
                        height: '100%',
                        background: `${newChats.includes(item.id) ? '#4FD1C5' : '#F8F9FA'}`,
                        borderRadius: '8px',
                    }}>
                    <Typography sx={{ fontSize: '1.1rem', fontWeight: '700' }}>
                        {item.Order.Professional.User.name}
                    </Typography>
                    <Divider mt={2} mb={2} />
                    <Typography sx={{ fontSize: '0.8rem', fontWeight: '500' }}>
                        Ordem: <strong>#{item.Order.id}</strong>
                    </Typography>

                    <Typography sx={{ fontSize: '0.8rem', fontWeight: '500' }}>
                        Cliente: <strong>{item.Order.Customer.customer_name}</strong>
                    </Typography>
                    <Divider mt={2} mb={2} />
                    {Boolean(item.Conversations.length) && (
                        <Typography sx={{ fontSize: '0.8rem', fontWeight: '500' }}>{item.Conversations[0].message}</Typography>
                    )}
                </Box>
            ))}
            {Boolean(openedChatService) && (
                <SupportDialog service={openedChatService} open={Boolean(openedChatService)} handleClose={() => setOpenedChatService()} />
            )}
        </>
    );
}
