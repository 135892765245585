import useSWR from 'swr';
import PropTypes from 'prop-types';
import api from '../services/api';

export function useFetch(url, params) {
     
    
    const { data, error, mutate } = useSWR(
        { url, params },
        async ({ url, params }) => {
            const { data } = await api.get(url, params);
            return data;
        },
        {
            errorRetryCount: 1,
        },
    );

    return { data: data?.data, error, isFetching: !error && !data, mutate };
}

useFetch.propTypes = {
    url: PropTypes.string,
    params: PropTypes.object,
};
