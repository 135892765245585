import React from 'react';
import { FormControl, InputLabel, Select, Box, MenuItem, OutlinedInput, Chip } from '@mui/material';
import { useFetch } from '../hooks/useFetch';

export default function SelectMultipleActivities({ value, onChange, name, error: err }) {
    const { data, error, mutate, isFetching } = useFetch('/activity');

    return (
        <FormControl error={Boolean(err)} fullWidth sx={{ marginTop: 2 }}>
            <InputLabel error={err} id="select-multiple-activities">
                Atividade
            </InputLabel>
            <Select
                fullWidth
                multiple
                name={name}
                value={value || []}
                onChange={onChange}
                input={<OutlinedInput label={isFetching ? 'Carregando...' : 'Atividade'} />}
                renderValue={selected => {
                    return (
                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                            {selected.map(value => (
                                <Chip key={value} label={data?.filter(({ id }) => id === value)[0].name} />
                            ))}
                        </Box>
                    );
                }}>
                {data?.map(item => (
                    <MenuItem key={item.id} value={item.id}>
                        {item.name}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
}
