import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useState } from 'react';
import api from '../../../services/api';
import useHandleErrors from '../../../hooks/useHandleErrors';
import { useEffect } from 'react';
import { Box, Checkbox, Chip, FormControlLabel, FormGroup, IconButton, Stack } from '@mui/material';
import PropTypes from 'prop-types';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import IconAdd from '@mui/icons-material/Add';
import LoadingButton from '@mui/lab/LoadingButton';
import SelectMultipleActivities from '../../../components/SelectMutipleActivities';
import { useSnackbar } from 'notistack';

export default function DialogAddAsk({ handleClose, open, mutate, data }) {
    const ValidateForm = Yup.object().shape({
        question: Yup.string().required('Campo obrigatório'),
        type: Yup.string().required('Campo obrigatório'),
        options: Yup.array().when('type', {
            is: value => value !== 'text',
            then: Yup.array().min(2, 'Adicione ao menos 2 opções').required('Campo obrigatório'),
        }),
        when: Yup.array().min(1, 'Selecione ao menos uma atividade').required('Campo obrigatório'),
        activities: Yup.array().min(1, 'Selecione ao menos uma atividade'),
        required: Yup.boolean().required('Campo obrigatório'),
    });

    const [errs, setErrs] = useState();
    useHandleErrors(errs);

    const { enqueueSnackbar } = useSnackbar();

    const [addOption, setAddOption] = useState('');

    const { values, errors, isSubmitting, setFieldValue, handleChange, handleBlur, handleReset, handleSubmit, setValues, validateForm } = useFormik({
        initialValues: {
            question: '',
            type: '',
            options: [],
            activities: [],
            required: false,
            when: [],
            include_report: false,
        },
        validationSchema: ValidateForm,
        onSubmit: async (values, { setSubmitting }) => {
            try {
                setSubmitting(true);
                if (Boolean(values.id)) {
                    await api.put(`/ask/${values.id}`, { ...values, when: values.when.join('|') });
                    enqueueSnackbar('Checklist alterado', { variant: 'success' });
                } else {
                    await api.post(`/ask`, { ...values, when: values.when.join('|') });
                    enqueueSnackbar('Novo checklist inserido', { variant: 'success' });
                }

                mutate();
                setSubmitting(false);
                handleReset();
            } catch (error) {
                setErrs(error);
            }
        },
    });

    useEffect(() => {
        if (!open) handleReset();
    }, [open]);

    useEffect(() => {
        if (data) {
            setValues(
                {
                    ...data,
                    when: data.when.split('|'),
                    activities: data.ActivityAsks.map(item => item.activity_id),
                    options: data.AskOptions.map(item => item.option),
                },
                false,
            );
            validateForm();
        }
    }, [data]);

    return (
        <form>
            <Dialog open={open} onClose={handleClose} disabled={isSubmitting}>
                <DialogTitle>{data ? 'Editando' : 'Adicionando'} checklist</DialogTitle>
                <DialogContent>
                    <DialogContentText sx={{ mb: 3 }}>Crie um checklist para adicionar antes ou depois da Ordem de Serviço.</DialogContentText>
                    <TextField
                        autoFocus
                        required
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.question}
                        helperText={errors.question}
                        error={Boolean(errors.question)}
                        margin="dense"
                        id="question"
                        label="Pergunta/Ação"
                        type="text"
                        name="question"
                        fullWidth
                        variant="outlined"
                        disabled={isSubmitting}
                    />

                    <FormControl error={Boolean(errors.required)} fullWidth sx={{ marginTop: 2 }}>
                        <InputLabel id="demo-simple-select-label">Obrigatória?</InputLabel>
                        <Select
                            autoFocus
                            required
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.required}
                            error={Boolean(errors.required)}
                            margin="dense"
                            id="required"
                            label="Obrigatória?"
                            type="text"
                            name="required"
                            fullWidth
                            variant="outlined"
                            disabled={isSubmitting}>
                            <MenuItem value={true}>Sim</MenuItem>
                            <MenuItem value={false}>Não</MenuItem>
                        </Select>
                    </FormControl>

                    <SelectMultipleActivities
                        value={values.activities}
                        name="activities"
                        onChange={handleChange}
                        error={Boolean(errors.activities)}
                    />

                    <FormControl fullWidth sx={{ marginTop: 2 }}>
                        <InputLabel id="demo-simple-select-label">Quando?</InputLabel>
                        <Select
                            multiple
                            required
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.when}
                            error={Boolean(errors.when)}
                            margin="dense"
                            id="when"
                            label="Tipo de resposta"
                            type="text"
                            name="when"
                            fullWidth
                            variant="outlined"
                            disabled={isSubmitting}>
                            <MenuItem value="before">Ao iniciar a ordem</MenuItem>
                            <MenuItem value="during">Durante a execução</MenuItem>
                            <MenuItem value="after">Ao finalizar a ordem</MenuItem>
                        </Select>
                    </FormControl>
                    <FormGroup sx={{my:2}}>
                        <FormControlLabel control={<Checkbox inputProps={{ 'aria-label': 'controlled' }} checked={values.include_report} onChange={(e,changed)=>setFieldValue('include_report', changed)} />} label="Incluir no relatório de avaliação" />
                    </FormGroup>
                    <FormControl fullWidth sx={{ marginTop: 2 }}>
                        <InputLabel id="demo-simple-select-label">Tipo de resposta</InputLabel>
                        <Select
                            autoFocus
                            required
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.type}
                            error={Boolean(errors.type)}
                            margin="dense"
                            id="type"
                            label="Tipo de resposta"
                            type="text"
                            name="type"
                            fullWidth
                            variant="outlined"
                            disabled={isSubmitting}>
                            <MenuItem value="text">Texto</MenuItem>
                            <MenuItem value="multiple">Múltipla Escolha</MenuItem>
                            <MenuItem value="single">Escolha única</MenuItem>
                        </Select>
                    </FormControl>

                    {['multiple', 'single'].includes(values.type) && (
                        <>
                            <Box sx={{ mt: 2 }}>
                                <DialogContentText>Adicione alternativas para seleção</DialogContentText>
                                <TextField
                                    required
                                    onChange={e => setAddOption(e.target.value)}
                                    onKeyDown={e => {
                                        if (e.keyCode === 13) {
                                            values.options.push(addOption);
                                            console.log(values.options);
                                            setFieldValue('options', values.options);
                                            setAddOption('');
                                        }
                                    }}
                                    InputProps={{
                                        endAdornment: (
                                            <IconButton
                                                color="primary"
                                                onClick={() => {
                                                    if (Boolean(addOption)) {
                                                        values.options.push(addOption);
                                                        console.log(values.options);
                                                        setFieldValue('options', values.options);
                                                        setAddOption('');
                                                    }
                                                }}>
                                                <IconAdd />
                                            </IconButton>
                                        ),
                                    }}
                                    value={addOption}
                                    onBlur={handleBlur}
                                    helperText={errors.options}
                                    error={Boolean(errors.options)}
                                    margin="dense"
                                    id="options"
                                    label="Pergunta/Ação"
                                    type="text"
                                    name="options"
                                    fullWidth
                                    variant="outlined"
                                    disabled={isSubmitting}
                                    sx={{ mb: 2 }}
                                />

                                <Stack direction="row" spacing={2}>
                                    {values.options?.map((item, index) => (
                                        <Chip
                                            key={item}
                                            label={item}
                                            onDelete={() => {
                                                values.options.splice(index, 1);
                                                setFieldValue('options', values.options);
                                            }}
                                        />
                                    ))}
                                </Stack>
                            </Box>
                        </>
                    )}
                </DialogContent>

                <DialogActions>
                    <Button onClick={handleClose} disabled={isSubmitting}>
                        Cancelar
                    </Button>
                    <LoadingButton
                        onClick={handleSubmit}
                        variant="contained"
                        sx={{ color: '#FAFAFA' }}
                        disabled={isSubmitting}
                        loading={isSubmitting}>
                        Salvar
                    </LoadingButton>
                </DialogActions>
            </Dialog>
        </form>
    );
}

DialogAddAsk.propTypes = {
    mutate: PropTypes.func.isRequired,
    handleClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    editData: PropTypes.object,
};
