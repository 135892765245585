import { Box } from "@mui/material";
import React from "react";

export function Separator() {

  return (
    <Box
      sx={{display: 'flex', height:"1px", width:"10rem", background:"linear-gradient(90deg, rgba(224, 225, 226, 0) 0%, #E0E1E2 49.52%, rgba(224, 225, 226, 0) 100%)"}}
    >
    </Box>
  );
}