import React, { useState } from 'react';
import { TextField, Stack, FormControl, InputLabel, Select, MenuItem, Chip, Box, OutlinedInput, FormHelperText, Typography } from '@mui/material';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import useHandleErrors from '../../../hooks/useHandleErrors';
import api from '../../../services/api';
import { LoadingButton } from '@mui/lab';
import { useSnackbar } from 'notistack';
import GlobalPaper from '../../../components/GlobalPaper';
import { useAuth } from '../../../contexts/AuthContext';
import { useEffect } from 'react';

// COMEÇO DO SELECT DE CHIPS =======

function getStyles(name, personName, theme) {
    return {
        fontWeight: personName.indexOf(name) === -1 ? theme.typography.fontWeightRegular : theme.typography.fontWeightMedium,
    };
}

// FIM DO SELECT DE CHIPS =======

export function FormEditUsers({ data }) {
    const { enqueueSnackbar } = useSnackbar();
    const { user, verifySession } = useAuth();

    const costumerFormScheema = Yup.object().shape({
        name: Yup.string().min(5, 'Nome do Responsável Incompleto').required('Campo obrigatório'),
        team: Yup.array().min(1, 'Selecione um time.').required('Campo obrigatório'),
        email: Yup.string().email('E-mail inválido.').required('Campo obrigatório'),
        password: Yup.string().min(6, 'Coloque no mínimo 6 caracteres.'),
    });

    const { handleChange, mutate, handleBlur, handleReset, handleSubmit, values, errors, isSubmitting, setFieldValue, setValues} = useFormik({
        initialValues: {
            name: '',
            email: '',
            team: []
         },
        validationSchema: costumerFormScheema,
        onSubmit: async (values, { setSubmitting }) => {
            try {
                setSubmitting(true);
                const {data}= await api.put(`user/${values.id}`, values);
                setSubmitting(false);
                if (user.id === values.id) {
                    sessionStorage.setItem('user', JSON.stringify({ ...user, name: values.name, email: values.email }));
                    verifySession();
                }
                setFieldValue('password', '')
                enqueueSnackbar(data.message, { variant: 'success' });
            } catch (error) {
                setError(error);
            }
        },
    });

    useEffect( ()=>{
        if(data){
            const {id, name, UserTeams, email} = data
            setValues({id, name, email, team: data.UserTeams.map(({ team }) => team)}, false)
        }
    },[data])

    const [error, setError] = useState();
    useHandleErrors(error);

    return (
        <>
            <GlobalPaper elevation={3}>
                <Stack direction="column" spacing={4}>
                    <Typography variant="h3">Dados de usuário</Typography>

                    <TextField
                        fullWidth
                        label="Nome"
                        variant="outlined"
                        sx={{ mb: '2rem' }}
                        disabled={isSubmitting}
                        error={Boolean(errors.name)}
                        helperText={errors.name}
                        name="name"
                        onChange={handleChange}
                        value={values.name}
                    />

                    <TextField
                        fullWidth
                        label="E-mail"
                        variant="outlined"
                        sx={{ mb: '2rem' }}
                        disabled={isSubmitting}
                        error={Boolean(errors.email)}
                        helperText={errors.email}
                        name="email"
                        onChange={handleChange}
                        value={values.email}
                    />

                    <Stack direction="row" spacing={4} sx={{ mb: '2rem' }}>
                        <FormControl sx={{ width: '50%' }}>
                            <InputLabel error={Boolean(errors.team)} id="demo-multiple-chip-label">
                                Selecione seu perfil
                            </InputLabel>
                            <Select
                                labelId="demo-multiple-chip-label"
                                id="demo-multiple-chip"
                                multiple
                                name="team"
                                //disabled={isSubmitting || user.id === data.id}
                                value={values.team}
                                onChange={handleChange}
                                input={<OutlinedInput id="select-multiple-chip" label="Selecione seu perfil" />}
                                error={Boolean(errors.team)}
                                renderValue={selected => (
                                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                        {selected.map(value => (
                                            <Chip key={value} label={value} />
                                        ))}
                                    </Box>
                                )}>
                                {['admin', 'professional'].map(name => (
                                    <MenuItem key={name} value={name}>
                                        {name}
                                    </MenuItem>
                                ))}
                            </Select>
                            <FormHelperText error={Boolean(errors.team)} disabled={Boolean(errors.team)}>
                                {errors.team}
                            </FormHelperText>
                        </FormControl>

                        {/* <TextField
                            fullWidth
                            label="Senha"
                            type="password"
                            variant="outlined"
                            disabled={isSubmitting}
                            sx={{ mb: '3rem' }}
                            error={Boolean(errors.password)}
                            helperText={errors.password}
                            name="password"
                            onChange={handleChange}
                            value={values.password}
                        /> */}
                    </Stack>

                    <Stack direction="row" spacing={4} justifyContent="flex-end">
                        <LoadingButton
                            loading={isSubmitting}
                            onClick={() => handleSubmit()}
                            size="large"
                            variant="contained"
                            sx={{ color: '#FAFAFA' }}>
                            Salvar
                        </LoadingButton>
                    </Stack>
                </Stack>
            </GlobalPaper>

            <GlobalPaper elevation={3}>
                <Stack direction="column" spacing={4}>
                    <Typography variant="h3">Mudar a senha</Typography>

                    <Stack direction="row" spacing={4} sx={{ mb: '2rem' }}>

                        <TextField
                            fullWidth
                            label="Senha"
                            type="password"
                            variant="outlined"
                            disabled={isSubmitting}
                            sx={{ mb: '3rem' }}
                            error={Boolean(errors.password)}
                            helperText={errors.password}
                            name="password"
                            onChange={handleChange}
                            value={values.password}
                        />
                    </Stack>

                    <Stack direction="row" spacing={4} justifyContent="flex-end">
                        <LoadingButton
                            loading={isSubmitting}
                            onClick={() => handleSubmit()}
                            size="large"
                            variant="contained"
                            sx={{ color: '#FAFAFA' }}>
                            Alterar
                        </LoadingButton>
                    </Stack>
                </Stack>
            </GlobalPaper>
        </>
    );
}
