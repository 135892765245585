import React from 'react';
import { useFetch } from '../../../hooks/useFetch';
import LoadingContainer, { NothingContent } from '../../../components/LoadingContent';
import { useParams } from 'react-router-dom';
import { FormEditUsers } from '../components/FormEditUsers';
import { FormEditProfessionals } from '../components/FormEditProfessionals';

export function EditUsers() {

  const {id} = useParams()
  
  const { data, error, mutate, isFetching } = useFetch(`/user/${id}`);
 
  if (isFetching) return <LoadingContainer />;
  if (!Boolean(data)) return <NothingContent label="Nenhum dado encontrado" />

  const { UserTeams } = data
  const allTeams = UserTeams.map( ({team})=> (team))
 
  return (
  <>
  <FormEditUsers data={data} />

  {allTeams.includes('professional')  && <FormEditProfessionals data={data} mutate={mutate} />}
  </>
)
}