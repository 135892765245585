import React from "react";
import { FormHelperText } from '@mui/material';
import { TextField, Autocomplete } from '@mui/material';
import { useFetch } from "../hooks/useFetch";

export function SelectActivities({ onChange, name, value, error: err, helperText}) {

  const { data, error, mutate, isFetching } = useFetch('/activity');

  const handleChange = e=>{
    if(e){
        onChange(e.id)
    }else{
        onChange('')
    }
}

  return ( 

      <>
      <Autocomplete
        fullWidth
        name={name}
        //isOptionEqualToValue={(option, value) => (option.id === value)}
        options={data || []}
        value={ data ? data.filter( item => item.id === Number(value))[0] : null}
        getOptionLabel={(option) => option.name }
        onChange={(e, selected )=> handleChange(selected)}
        renderInput={(params) => <TextField {...params} label="Selecione uma atividade" />}
      />
      <FormHelperText error={Boolean(err)}>
        {err}
      </FormHelperText>
    </>

  );
}