import { Box, CircularProgress, Typography } from '@mui/material';
import React from 'react';
import PropTypes from 'prop-types';
import Nothing from '@mui/icons-material/DoNotDisturb'

export default function LoadingContainer(){

    return(
        <Box sx={{mt:"10rem",mb:"10rem",textAlign:"center"}}>
        <CircularProgress color="success" />
        </Box>
    )
}

export function NothingContent({label}){
    return (
        <Box sx={{mt:"10rem",mb:"10rem",textAlign:"center"}}>
            <Nothing color="disabled" sx={{fontSize:60}} />
            {label && <Typography color="disabled">{label}</Typography>}
        </Box>
    )
}

NothingContent.propTypes = {
    label: PropTypes.string
}