import React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
// import { Separator } from '../../../components/Separator';
import LoadingContainer, { NothingContent } from '../../../components/LoadingContent';
import { useFetch } from '../../../hooks/useFetch';
import { Box, Divider, Stack } from '@mui/material';

export function Cards({ name, image, icon, price }) {
    return (
        <Card elevation={3} sx={{ borderRadius: '8px', flexGrow: 2, py: 5, alignItems: 'center' }}>
            <CardContent>
                <Stack direction={{ xs: 'row', md: 'column' }} justifyContent={{ xs: 'space-around', md: 'center' }} alignItems="center">
                    <Box
                        bgcolor="primary.main"
                        sx={{
                            width: '4rem',
                            height: '4rem',
                            mb: { xs: 0, md: 1 },
                            boxShadow: 'none',
                            borderRadius: '12px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}>
                        {icon}
                    </Box>

                    <Box textAlign="center">
                        <Typography sx={{ fontSize: '1.12rem', fontWeight: '700', color: '#2D3748' }}>{name}</Typography>
                        <Typography sx={{ fontSize: '0.80rem', fontWeight: '700', color: '#A0AEC0' }}>Total</Typography>
                        <Divider mt={5} mb={5} />
                        <Typography sx={{ color: '#2D3748', fontWeight: '700', fontSize: '1.12' }}>{price}</Typography>
                    </Box>
                </Stack>
            </CardContent>
        </Card>
    );
}
