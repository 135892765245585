import React from 'react';
import { TextField, Autocomplete, FormHelperText } from '@mui/material';
import { useFetch } from '../hooks/useFetch';

export function SelectCustomers({ value, onChange, name, error: err, helperText }) {
    const { data, error, mutate, isFetching } = useFetch('/customer');

    const labels = data || []

    const handleChange = e => {
        if (e) {
            onChange(e.id);
        } else {
            onChange('');
        }
    };

    return (
        <>
            <Autocomplete
                fullWidth
                loading={isFetching}
                loadingText="Carregando..."
                name={name}
                options={labels}
                value={labels.filter(item => item.id === Number(value))[0] || null}
                getOptionLabel={option => option.customer_name}
                onChange={(e, selected) => handleChange(selected)}
                renderInput={params => <TextField {...params} label="Selecione um cliente" />}
            />
            <FormHelperText error={Boolean(err)}>{err}</FormHelperText>
        </>
    );
}
