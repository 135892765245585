import React from 'react';
import { TextField, Autocomplete, FormHelperText } from '@mui/material';
import { useFetch } from '../../../hooks/useFetch';
import { useState } from 'react';
import { useEffect } from 'react';

export function SelectProfessional({ value, activity_id, onChange, name, error: err, helperText }) {
    const [params, setParams] = useState({});
    const { data, error, mutate, isFetching } = useFetch('/professional', {
        params,
    });

    useEffect(() => {
        if (activity_id) {
            setParams({ activity_id });
        }
    }, [activity_id]);

    const labels = data ? data.map(item => ({ label: item.User.name, id: item.id })) : [];

    return (
        <>
            <Autocomplete
                fullWidth
                loading={isFetching}
                loadingText="Carregando..."
                disabled={!Boolean(activity_id)}
                name={name}
                options={labels}
                value={labels.filter(item => item.id === value)[0] || null}
                getOptionLabel={option => option.label}
                onChange={(e, selected) => onChange(name, selected ? selected.id : '')}
                renderInput={params => <TextField {...params} error={Boolean(err)} label="Selecione um profissional" />}
            />
            <FormHelperText error={Boolean(err)}>{err}</FormHelperText>
        </>
    );
}
