import { createTheme, ThemeProvider } from '@mui/material';
import { BrowserRouter } from 'react-router-dom';
import { AuthProvider } from './contexts/AuthContext';
import Routes from './routes';
import { SnackbarProvider } from 'notistack';
import { CookiesProvider } from 'react-cookie';

import './app.css';
import { AppProvider } from './contexts/AppContext';

export function App() {
    const theme = createTheme({
        breakpoints: {
            values: {
                xs: 0,
                sm: 600,
                md: 900,
                lg: 1200,
                xl: 1536,
            },
        },
        palette: {
            mode: 'light',
        },
        typography: {
            h3: {
                fontWeight: 900,
                fontSize: '1.3rem',
            },
            h4: {
                fontWeight: 700,
                fontSize: '1.1rem',
            },
            h5: {
                fontWeight: 700,
                fontSize: '0.9rem',
            },
            h4: {
                fontWeight: 700,
                fontSize: '0.75rem',
            },
        },
    });

    return (
        <ThemeProvider theme={theme}>
            <BrowserRouter>
                <SnackbarProvider
                    //maxSnack={3}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}>
                    <CookiesProvider>
                        <AuthProvider>
                            <AppProvider>
                                <Routes />
                            </AppProvider>
                        </AuthProvider>
                    </CookiesProvider>
                </SnackbarProvider>
            </BrowserRouter>
        </ThemeProvider>
    );
}
