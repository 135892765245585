import React from 'react';
import { useFetch } from '../../../hooks/useFetch';
import LoadingContainer, { NothingContent } from '../../../components/LoadingContent';
import { useParams } from 'react-router-dom';
import FormEditCustomer from '../components/FormEditCustomer'

export default function EditCustomers() {

  const {id} = useParams()
  
  const { data, error, mutate, isFetching } = useFetch(`/customer/${id}`);
  
  if (isFetching) return <LoadingContainer />;
  if (!Boolean(data)) return <NothingContent label="Nenhum dado encontrado" />

  return <FormEditCustomer data={data} mutate={mutate} />

}