import SpeedDial from '@mui/material/SpeedDial';
import SpeedDialIcon from '@mui/material/SpeedDialIcon';
import SpeedDialAction from '@mui/material/SpeedDialAction';
import PersonIcon from '@mui/icons-material/Person';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import SignalCellularAltIcon from '@mui/icons-material/SignalCellularAlt';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';

const actions = [
    { icon: <SignalCellularAltIcon />, name: 'Nova ordem', to: 'orders/new' },
    { icon: <InsertDriveFileIcon />, name: 'Novo cliente', to: 'customers/new' },
    { icon: <PersonIcon />, name: 'Novo prestador', to: 'team/new' },
];

export default function FreshActions() {
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const navigation = useNavigate();

    return (
        <SpeedDial
            ariaLabel="SpeedDial tooltip example"
            sx={{ position: 'absolute', bottom: 50, right: 50 }}
            icon={<SpeedDialIcon />}
            onClose={handleClose}
            onOpen={handleOpen}
            open={open}>
            {actions.map(action => (
                <SpeedDialAction key={action.name} icon={action.icon} tooltipTitle={action.name} tooltipOpen onClick={() => navigation(action.to)} />
            ))}
        </SpeedDial>
    );
}
