import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import { Button, Container, Stack, Typography } from '@mui/material';
import SupportFeed from './SupportFeed';
import { useAuth } from '../contexts/AuthContext';
import { useFetch } from '../hooks/useFetch';

export function SupportAsside() {
    const { data, error, mutate, isFetching } = useFetch(`support`);

    return (
         
            <Box p={2} width="400px">
                <Stack direction="column" spacing={2}>
                    <SupportFeed data={data} mutate={mutate} isFetching={isFetching} />
                </Stack>
            </Box>
         
    );
}
